<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <ylomi-logo />
        </b-link>

        <b-card-title class="mb-1">
          Bienvenue sur Ylomi! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Merci de vous authentifier pour prendre part à l'administration de
          ylomi
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{}"
        >
          <b-form
            autocomplete="off"
            class="auth-login-form mt-2"
            @submit.prevent="submitForm"
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Adresse email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="loginData.email"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez votre adresse email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
                <b-link :to="{ name: 'forgot-password' }">
                  <small>Mot de passe oublié ?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="mot-de-passe"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="loginData.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez votre mot de passe"
                    name="login-password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Valider</span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BSpinner,
  BInputGroupAppend,
} from 'bootstrap-vue'
import YlomiLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    YlomiLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      loginData: {
        email: '',
        password: '',
      },
      status: '',
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('auth', ['loginAction']),

    submitForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.loginAction(this.loginData)
            .then(response => {
              this.isLoading = false
              if (response && response.data.role.id !== 1) {
                this.$router.push({ name: 'dashboard-index' })
              } else {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: "Vous n'êtes pas un administrateur ylomi",
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              }
            })
            .catch(error => {
              this.isLoading = false
              if (error.response) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: `${error.response.data.message}`,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
